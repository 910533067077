"use client"

import type { DetailedHTMLProps, ElementType, HTMLAttributes } from "react"
import clsx from "clsx"
import {
  MARK_LINK,
  MARK_TEXT_STYLE,
  NODE_PARAGRAPH,
  type RenderOptions as StoryblokRenderOptions,
} from "storyblok-rich-text-react-renderer"

import type { ExplicitAny } from "~/@types/generics"
import { type SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import { sbLabelWithLinkLabelSchema } from "~/lib/storyblok/schemas/library/components/label_with_link_label"
import { sbComponentLinkLabelSchema } from "~/lib/storyblok/schemas/library/components/link_label"
import { sbComponentTableItem } from "~/lib/storyblok/schemas/library/components/table_item"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import useLocale from "~/hooks/useLocale"
import { Image } from "~/components/ui/Image"
import InlineCta from "~/components/ui/InlineCta"
import { LabelWithInlineCta } from "~/components/ui/LabelWithInlineCta"
import { serializeRichTextLink, type RichTextLink } from "~/components/ui/RichText/_data/serialize-richt-text-link"
import { RichtextAccordion, TableItems, type TableItemProps } from "~/components/ui/RichTextAccordion"
import AbstractRichText from "~/components/abstracts/RichText"
import type { TSizeGuideTable } from "~/components/slices/SizeGuideSlice/SizeGuideTable"

import * as css from "./styles.css"

interface RenderOptions extends StoryblokRenderOptions {
  blokResolvers?: {
    [key: string]: (props: ExplicitAny) => JSX.Element | null
  }
}

interface RichTextProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  as?: ElementType
  nodeClassNames?: Partial<NodeClassNames>
  render: SbRichtext | undefined | null
  options?: RenderOptions
  disableStyles?: boolean
}

type NodeClassNames = Record<
  keyof Required<StoryblokRenderOptions>["nodeResolvers"],
  string | ((props: number) => string | null)
>

type SbTableItem = {
  table_title: SbRichtext
  table_paragraph: SbRichtext
  table: [TSizeGuideTable]
}

type AccordionItem = {
  title: string
  text: SbRichtext
  table_items: SbTableItem[]
}

export function HardBreak() {
  return (
    <span className={css.br}>
      <br />
    </span>
  )
}

function RichText({ className, as, style, render, nodeClassNames, disableStyles }: RichTextProps) {
  const locale = useLocale()
  function _getNodeClassName(key: keyof NodeClassNames, level?: number) {
    const cn = nodeClassNames?.[key]
    const nodeClassName = cn && level && typeof cn !== "string" ? cn(level) : cn ?? null
    return nodeClassName as string | null
  }

  return (
    <AbstractRichText
      className={clsx(!disableStyles && css.RichText, className)}
      as={as}
      style={style}
      render={render}
      options={{
        blokResolvers: {
          ["image"]: (props: ExplicitAny) => {
            const REGEX = /\/([0-9]+)x([0-9]+)\//
            const result = REGEX.exec(props?.image?.filename) || []
            const [, width, height] = result

            return (
              <Image
                className={css.Image}
                {...props}
                src={props?.image?.filename ?? null}
                alt={props?.image?.alt ?? null}
                width={width}
                height={height}
              />
            )
          },
          ["accordion_item"]: (props: ExplicitAny) => {
            const items =
              props.items?.map((item: AccordionItem) => {
                return {
                  head: item?.title,
                  body: item?.text,
                  tableItems: item?.table_items?.map((tableItem) => {
                    return handleZodSafeParse(sbComponentTableItem(), tableItem)
                  }),
                }
              }) ?? []

            return <RichtextAccordion className={css.accordion} items={items} />
          },
          ["table_item"]: (props: ExplicitAny) => {
            const item = handleZodSafeParse(sbComponentTableItem(), props) ?? ({} as TableItemProps)
            return <TableItems tableItems={[item]}></TableItems>
          },
          ["label_with_link_label"]: (props) => {
            const cta = handleZodSafeParse(sbLabelWithLinkLabelSchema({ locale }), {
              ...props,
              component: "label_with_link_label",
            })

            if (!cta) return null

            return (
              <LabelWithInlineCta
                className={css.labelWithInlineCta}
                {...cta}
                ctaProps={{ withLine: true, withDefaultLine: true, weight: "medium", size: "small" }}
              />
            )
          },
          ["link_label"]: (props) => {
            const cta = handleZodSafeParse(sbComponentLinkLabelSchema({ locale }), {
              ...props,
              component: "link_label",
            })
            if (!cta) return null

            return <InlineCta className={clsx(css.labelWithInlineCta, css.link)} {...cta} color="current" size="none" />
          },
        },
        nodeResolvers: {
          [NODE_PARAGRAPH]: (children) => {
            const result = children?.toString() !== undefined ? <p>{children}</p> : <br /> ?? children
            return result
          },
        },

        markResolvers: {
          [MARK_TEXT_STYLE]: (children) => {
            //when the customer copy paste a text with a textColor
            //that remove the style rgb(x, x, x)
            return <>{children}</>
          },
          [MARK_LINK]: (children, props) => {
            const link = serializeRichTextLink(props as RichTextLink, locale)

            return (
              <InlineCta className={clsx(css.link)} {...link} color="current" size="none">
                {children}
              </InlineCta>
            )
          },
        },
      }}
    />
  )
}

export type { StoryblokRichtext as RichTextBlock } from "storyblok-rich-text-react-renderer"
export default RichText
